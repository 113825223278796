//https://www.bezkoder.com/react-login-example-jwt-hooks/

import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthService from "../services/AuthService";

import Strings from "../common/Strings";

const Login = ({ setCurrentAuth }) => {
    const navigate = useNavigate();

    if (AuthService.getCurrentUser()) {
        navigate("/");
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState("");
    const validationSchema = yup.object().shape({
        email: yup.string()
            .required(Strings['validation']['required'])
            .email(Strings['validation']['email']),
        password: yup.string()
            .required(Strings['validation']['required'])
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleLogin = (data) => {
        AuthService.login(data.email, data.password).then(
            () => {
                setCurrentAuth(AuthService.getCurrentUser());
                const return_url = searchParams.get("return_url");

                if (return_url) {
                    navigate(return_url);
                } else {
                    navigate("/");
                }
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                setMessage(Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage);
            }
        );

    };

    return (
        <div className="d-flex justify-content-center align-items-center flex-column">
            <h1>Пожалуйста, авторизуйтесь</h1>

            <form onSubmit={handleSubmit(handleLogin)}>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.email?.message}</div>
                </div>

                <div className="form-group">
                    <label>Пароль</label>
                    <input
                        name="password"
                        type="password"
                        {...register('password')}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.password?.message}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Войти
                    </button>
                    <a href="/register">Регистрация</a>
                </div>
                <a href="/restore-password">Забыли пароль?</a>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default Login;