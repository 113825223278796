import React, { useState, useRef, useEffect } from "react";
import ProductsService from "../services/ProductsService";
import UsersService from "../services/UsersService";
import ImportExportItem from "./ImportExportItem";
import FileUploader from "../common/FileUploader";


const ImportExport = () => {

    const [error, setError] = useState("");

    return (
        <div>
            <h1>Импорт и экспорт базы</h1>
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}
            <h2>Товары: сокращенный формат</h2>
            <ImportExportItem importFunc={ProductsService.importProductsNew} exportFunc={ProductsService.exportProductsNew} filename_prefix="catalog_new" />
            <hr />
            <h2>Товары: расширенный формат</h2>
            <ImportExportItem importFunc={ProductsService.importProducts} exportFunc={ProductsService.exportProducts} filename_prefix="catalog" />
            <hr />
            <h2>Пользователи</h2>
            <ImportExportItem importFunc={UsersService.importUsers} exportFunc={UsersService.exportUsers} filename_prefix="users" />
            <hr />
            <h2>Изображения</h2>
            <p>Загрузите zip-файл</p>
            <FileUploader uploadFunc={ProductsService.importProductsImages} />
        </div>
    );
};

export default ImportExport;