import AuthService from "./services/AuthService";
import Login from "./auth/Login";


function NotFoundPage() {

    return <div>
        <h1 className="text-center">404 - Страница не найдена</h1>
        <p className="offset-2 col-8">Компания «Fresh Coffee» является крупнейшим оптовым поставщиком кофе по всей России, а также в странах СНГ. 
            Собственное производство позволяет нам обжаривать кофе непосредственно перед отправкой заказа, 
            тем самым, наши клиенты всегда получают свежеобжаренный кофе.</p>

        <p className="text-center"><a href="/catalog" className="btn btn-lg btn-primary">Перейти в каталог</a></p>
    </div>
}

export default NotFoundPage;