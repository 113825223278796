import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrdersService from "../services/OrdersService";
import { format, parseISO } from 'date-fns'

const OrderDocs = ({ setLoading, setError, allowUpload }) => {
    const { orderId } = useParams();
    const [orderDocs, setOrderDocs] = useState("");

    const getOrderDocs = () => {
        setLoading(true);
        OrdersService.getOrderDocs(orderId).then(
            (response) => {
                setOrderDocs(response);
                //console.log(response);
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };

    const dounloadOrderInfoDoc = () => {
        OrdersService.getOrderInfoDoc(orderId).then(
            (response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Заказ_№' + orderId + '_' + format(new Date(), "dd-MM-yyyy_HH-mm") + '.docx');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    };

    const downloadFile = (doc) => {
        //console.log(doc);
        OrdersService.getOrderDoc(orderId, doc.document_id).then(
            (response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', doc.filename);
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    }
    useEffect(() => {
        getOrderDocs();
    }, []);

    const orderDocsItems = orderDocs.length == 0 ? "Файлов пока нет." : orderDocs.map((doc) => {
        return <div key={doc.document_id} className="mb-2"><button onClick={(e) => downloadFile(doc)} className="btn btn-outline-success me-2"><i className="fa fa-download"></i></button>{doc.filename}</div>
    });

    const [file, setFile] = useState(''); // storing the uploaded file    
    const [progress, setProgess] = useState(0); // progess bar

    const el = useRef(); // accesing input element
    const handleChange = (e) => {
        setProgess(0)
        const file = e.target.files[0]; // accesing file
        console.log(file);
        setFile(file); // storing file
    }
    const uploadFile = () => {
        if (!file) {
            el.current.click();
            return;
        }
        const formData = new FormData();
        formData.append('file', file); // appending file
        const onUploadProgress = (ProgressEvent) => {
            let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);
            getOrderDocs();
        }
        OrdersService.addOrderDoc(orderId, formData, onUploadProgress).then(
            (response) => {
                setFile("");
                getOrderDocs();
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    }

    return <div>
        <h2>Файлы заказа</h2>
        <button onClick={dounloadOrderInfoDoc} className="btn btn-success">Скачать файл с информацией о заказе</button>
        <hr />
        {orderDocsItems}
        {allowUpload && (<div className="file-upload mt-4">
            <input type="file" ref={el} onChange={handleChange} className="d-none" />
            {/* <div className="progessBar" style={{ width: progress }}>
                            {progress}
                        </div> */}
            <div className="d-flex align-items-center">
                <button onClick={uploadFile} className="btn btn-success me-4">
                    {file ? "Начать загрузку" : "Выбрать файл"}
                </button>
                {file ? file.name : ""}
            </div>
        </div>)}
    </div>
}

export default OrderDocs;