
import React, { useState, useEffect } from "react";
import OrdersService from "../services/OrdersService";
import { format, parseISO } from 'date-fns'

const OrdersHistory = ({setLoading}) => {

    const [orders, setOrders] = useState();

    const getOrders = () => {
        setLoading(true);
        OrdersService.getOrders().then(
            (response) => {
                setOrders(response);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        getOrders();

    }, []);
    const orderItems = !orders ? "Заказов пока нет" : orders.map((order) => {
        return <div className="order-list-item col-lg-6" key={order.id}>
            <div className="card">
                <h5 className="card-header"> #{order.id} | {format(parseISO(order.creation_date), "dd.MM.yyyy HH:mm")}</h5>
                <div className="card-body">
                    <p className="card-title">Сумма заказа: {order.total} руб.</p>
                    <p className="card-title">Статус: {order.status}</p>
                    <p className="card-text">
                        {order.client_name}<br/>
                        {order.email}<br/>
                        {order.phone}<br/>

                    </p>
                    <a href={"/personal/orders/"+ order.id} className="btn btn-primary">Подробнее</a>
                </div>
            </div>
        </div>
    })

    return (
        <div className="">
            <h2>История заказов</h2>
            <div className="row">{orderItems}</div>
        </div>
    );
}

export default OrdersHistory;