import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getCategories = (parent_id) => {
    return axios.get(API_URL + 'categories/children/' + parent_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const getCategory = (category_id) => {
    return axios.get(API_URL + 'categories/' + category_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const getAllCategories = () => {
    return axios.get(API_URL + 'categories', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const getRootCategoriesWithChildrenPlainList = () => {
    return axios.get(API_URL + 'categories/children_plain_list_for_root_categories', AuthService.getTokenHeaders())
        .then(function (response) {
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const CategoriesService = {
    getCategories,
    getCategory,
    getAllCategories,
    getRootCategoriesWithChildrenPlainList
}

export default CategoriesService;