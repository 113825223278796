import React, { useState, useRef, useEffect } from "react";
import { format, parseISO } from 'date-fns'

const ImportExportItem = ({importFunc, exportFunc, filename_prefix}) => {
    const [file, setFile] = useState(''); // storing the uploaded file    
    // storing the recived file from backend
    //const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0); // progess bar
    const [error, setError] = useState("");
    const el = useRef(); // accesing input element
    const handleChange = (e) => {
        setProgess(0)
        const file = e.target.files[0]; // accesing file
        //console.log(file);
        setFile(file); // storing file
    }
    const uploadFile = () => {
        if (!file) {
            el.current.click();
            return;
        }
        const formData = new FormData();
        formData.append('file', file); // appending file
        const onUploadProgress = (ProgressEvent) => {
            let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);
        }
        importFunc(formData, onUploadProgress).then(
            (response) => {
                setFile("");
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setError(resMessage);
            }
        );
    }
    const downloadFile = () => {
        exportFunc().then(
            (response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename_prefix + '_' + format(new Date(), "dd-MM-yyyy_HH-mm") + '.xlsx');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    }
    return (
        <div>
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}
            <div className="row">
                <div className="col-6">
                    <h3>Загрузить</h3>
                    <div className="file-upload">
                        <input type="file" ref={el} onChange={handleChange} className="d-none" />
                        {/* <div className="progessBar" style={{ width: progress }}>
                            {progress}
                        </div> */}
                        <div className="d-flex align-items-center">
                            <button onClick={uploadFile} className="btn btn-success me-4">
                                {file ? "Начать загрузку" : "Выбрать файл"}
                            </button>
                            {file ? file.name : ""}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <h3>Скачать</h3>
                    <div className="">
                        <button onClick={downloadFile} className="btn btn-success">Скачать файл</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportExportItem;