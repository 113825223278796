import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns'
import OrdersService from "../services/OrdersService";
import OrderItems from "../common/OrderItems";
import CartService from "../services/CartService";
import OrderDocs from "../common/OrderDocs";
import OrderStatusHistory from "../common/OrderStatusHistory";

const OrderInfo = ({ setLoading }) => {
    const { orderId } = useParams();
    const [error, setError] = useState("");
    const [order, setOrder] = useState("");

    const navigate = useNavigate();

    const getOrder = () => {
        setLoading(true);
        OrdersService.getOrder(orderId).then(
            (response) => {
                setOrder(response);
                console.log(response);
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };

    const repeatOrder = () => {
        setLoading(true);
        CartService.repeatOrder(orderId).then(
            (response) => {
                navigate("/checkout");
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        getOrder();
    }, []);

    const orderHistoryItems = !order || order.status_history.length == 0 ? "Заказ создан." : order.status_history.map((statusHistoryItem) => {
        return <div key={statusHistoryItem.change_id}>{format(parseISO(statusHistoryItem.creation_date), "dd.MM.yyyy HH:mm")}: {statusHistoryItem.old_status} &rarr; {statusHistoryItem.new_status}</div>
    });

    return (
        <div className="">
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}
            <h1>Заказ #{orderId}</h1>
            {order != "" && (<div>
                <div className="row">
                    <div className="col-md-6">
                        <p>Дата оформления заказа: {format(parseISO(order.creation_date), "dd.MM.yyyy HH:mm")}</p>
                        <p>Статус: {order.status}</p>
                    </div>
                    <div className="col-md-6"><a href="#" onClick={repeatOrder} className="btn btn-primary">Повторить заказ</a></div>
                </div>
                <OrderStatusHistory order={order} />
                <hr />
                <div className="row">
                    <div className="col-lg-6">
                        {<OrderItems order={order} setLoading={setLoading}/>}
                    </div>
                    <div className="col-lg-6">
                        <h2>Покупатель</h2>
                        <hr />
                        <p>Имя: {order.client_name}</p>
                        <p>Телефон: {order.phone}</p>
                        <p>Email: {order.email}</p>
                        <p>Комментарий покупателя: {order.client_comment}</p>
                        <hr />
                        <h2>Доставка</h2>
                        <hr />
                        <p>Адрес доставки: {order.address}</p>
                        <p>Способ доставки: {order.delivery_type}</p>
                        <p>Дополнительная информация о доставке: {order.delivery_comment}</p>
                        <hr />
                        <h2>Получатель доставки</h2>
                        <hr />
                        <p>Имя получателя заказа: {order.name_order_accepter}</p>
                        <p>Телефон получателя заказа: {order.phone_order_accepter}</p>
                        <hr />
                        
                        
                        <OrderDocs setLoading={setLoading} setError={setError} />
                        
                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default OrderInfo;