import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Categories from "./Categories";
import PriceListCategory from "./PriceListCategory";

import ProductsService from "./services/ProductsService";

const PriceList = ({ refreshCart, setLoading, isProductInCart }) => {
    const navigate = useNavigate();
    const [title, setTitle] = useState("Прайс-лист");
    const [error, setError] = useState("");
    const [productsTree, setProductsTree] = useState("");
    const { categoryId } = useParams();

    const getPriceList = () => {

        setLoading(true);
        ProductsService.getProductsTree(categoryId).then(
            (response) => {
                setProductsTree(response);
                if(categoryId) {
                    document.title = response[0].name + " - FreshCoffee";
                } else {
                    document.title = "Прайс-лист - FreshCoffee";
                }
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };

    useEffect(() => {

        getPriceList();
        refreshCart();


    }, []);

    const tree = !productsTree ? "" : productsTree.map((category) => {
        return <PriceListCategory key={category.category_id} category={category} level={2} refreshCart={refreshCart} setLoading={setLoading} isProductInCart={isProductInCart} />
    });


    return (
        <div>
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}
            <div className="d-flex justify-content-between align-items-center">
                <h1>{title}</h1>
                {<button className="btn btn-secondary mb-4" onClick={() => navigate(-1)}>Назад</button>}
            </div>
            
            <Categories urlPrefix="pricelist" />

            {tree}
        </div>
    )

}

export default PriceList;