import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getOrders = () => {
    return axios.get(API_URL + 'orders', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const getOrdersAll = () => {
    return axios.get(API_URL + 'orders/all?sort=creation_date_desc', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const getOrder = (order_id) => {
    return axios.get(API_URL + 'orders/' + order_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const place_an_order = (data) => {
    return axios.post(API_URL + "orders", data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const getOrderDocs = (order_id) => {
    return axios.get(API_URL + 'orders/docs/' + order_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const addOrderDoc = (order_id, formData, onUploadProgress) => {

    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    //console.log(formData);
    return axios.put(API_URL + 'orders/docs/' + order_id, formData, headers).then(response => {
        return response.data;
    }).catch(err => console.log(err))
}

const getOrderDoc = (orderId, documentId) => {
    return axios({
        url: API_URL + 'orders/docs/' + orderId + '/' + documentId,
        method: 'GET',
        responseType: 'blob',
        headers: AuthService.getTokenHeaders().headers
    }, AuthService.getTokenHeaders()).then((response) => {
        return response;
    });
}

const getStatuses = () => {
    return axios.get(API_URL + 'orders/statuses', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const updateOrderStatus = (orderId, status) => {
    return axios.post(API_URL + "orders/set_status", { order_id: orderId, status: status }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const updateOrder = (order) => {
    return axios.post(API_URL + "orders/" + order.id, order, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const getOrderHistory = (order_id) => {
    return axios.get(API_URL + 'orders/' + order_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const getOrderInfoDoc = (order_id) => {
    return axios({
        url: API_URL + 'orders/generate_file_with_order/' + order_id,
        method: 'GET',
        responseType: 'blob',
        headers: AuthService.getTokenHeaders().headers
    }, AuthService.getTokenHeaders()).then((response) => {
        return response;
    });
}

const OrdersService = {
    getOrders,
    place_an_order,
    getOrdersAll,
    getOrder,
    getOrderDocs,
    addOrderDoc,
    getOrderDoc,
    getStatuses,
    updateOrder,
    updateOrderStatus,
    getOrderInfoDoc,
    
}

export default OrdersService;