import CartService from "./services/CartService";

const Cart = ({ cart, refreshCart, grouppedView }) => {

    const deleteFromCart = (e, cart_item_id) => {
        CartService.deleteFromCart(cart_item_id).then(
            (response) => {
                refreshCart();
            }
        );
    }

    const deleteFromCartByName = (e, cartItemName) => {
        let pr = [];
        cart.items.forEach((cartItem, index) => {
            if (cartItem.product.name === cartItemName) {
                pr.push(CartService.deleteFromCart(cartItem.cart_item_id));
            }
        });

        Promise.all(pr).then(() => {
            refreshCart();
        });
    }

    const cartItems = !cart || !cart.items ? "Корзина пуста" : cart.items.map((cartItem) => {
        return <div className="row align-items-center mb-2" key={cartItem.cart_item_id}>
            <div className="col-5">{cartItem.product.name}</div>
            <div className="col-2">{cartItem.product.price} руб.</div>
            <div className="col-2">{cartItem.quantity} шт.</div>
            <div className="col-2">{cartItem.price} руб.</div>
            <div className="col-1">
                <button onClick={(e) => deleteFromCart(e, cartItem.cart_item_id)} className="btn btn-outline-warning"><i className="fa fa-trash-o"></i></button></div>
        </div>
    });

    const groupedCartItems = !cart || !cart.items ? [] : cart.items.reduce((groupedItems, currentItem) => {
        let it = groupedItems.find(x => x.id === currentItem.product.id);
        if (!it) {
            it = {
                "id": currentItem.product.id,
                "name": currentItem.product.name,
                "items": [currentItem],
                "enabled": currentItem.product.enabled
            };
            groupedItems.push(it);
        } else {
            it["items"].push(currentItem);
        }

        return groupedItems;
    }, []);

    let idx = 0;
    const groupedCartItemsRender = !groupedCartItems || groupedCartItems.length == 0 ? "Корзина пуста" : groupedCartItems.map((groupedItem) => {
        idx++;
        return <div className={"d-flex align-items-center mb-2 justify-content-between" + (!groupedItem.enabled ? " text-danger" : "")} key={groupedItem.name}>
            <div className="">{idx}. {groupedItem.name} {!groupedItem.enabled && "!!!временно недоступен!!!"}</div>
            <div className="">
                <button onClick={(e) => deleteFromCartByName(e, groupedItem.name)} className="btn btn-outline-warning"><i className="fa fa-trash-o"></i></button></div>
        </div>
    });


    return (
        <div className="cartDialog" id="cart">
            {grouppedView ? groupedCartItemsRender : cartItems}

            <hr />
            {groupedCartItems.length > 0 && (
                <div className="d-flex justify-content-between align-items-center">
                    {!grouppedView ?? <div>Всего: {cart.total} руб.</div>}
                    <div><a className="btn btn-primary" href="/checkout">Перейти к оформлению</a></div>
                </div>
            )}
        </div>
    );

};

export default Cart;