

const Strings = {
    errors: {
        'Incorrect username or password': 'Неправильное имя или пароль пользователя',
        'Disabled': 'Ваша учетная запись отключена.'
    },
    validation: {
        'required': 'Поле обязательно для заполнения',
        'email': 'Введите правильный Email'
    }
}

export default Strings