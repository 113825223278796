import axios from "axios";
import AuthService from "./AuthService";
import originHeaders from "./AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;


const getUsers = () => {
    return axios.get(API_URL + 'users', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const getGroups = () => {
    return axios.get(API_URL + 'usergroups', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const createUser = (email, password, user_groups) => {
    return axios.post(API_URL + "users", {
        email,
        password,
        user_groups: user_groups
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const updateUser = (id, email, user_groups, disabled) => {
    return axios.post(API_URL + "users/" + id, {
        id: id,
        email,
        user_groups: user_groups,
        disabled: disabled
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const deleteUser = (userId) => {
    return axios.delete(API_URL + "users/" + userId,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const createGroup = (name) => {
    return axios.post(API_URL + "usergroups", {
        name
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const editGroup = (name) => {
    return axios.put(API_URL + "usergroups", {
        name
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const deleteGroup = (groupId) => {
    return axios.delete(API_URL + "usergroups/" + groupId,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const resetPassword = (email) => {
    return axios.post(API_URL + "users/reset_password", {
        email
    }, originHeaders)
        .then((response) => {
            return response.data;
        });
}

const importUsers = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    console.log(formData);
    return axios.post(API_URL + 'users/import', formData, headers).then(response => {
        return response.data;
    });
}

const exportUsers = () => {
    return axios({
        url: API_URL + 'users/download',
        method: 'GET',
        responseType: 'blob',
        headers: AuthService.getTokenHeaders().headers
    }, AuthService.getTokenHeaders()).then((response) => {
        return response;
    });
}

const proofResetPassword = (data) => {
    return axios.post(API_URL + "users/proof_reset_password", data, originHeaders)
        .then((response) => {
            return response.data;
        });
}

const getCurrentUser = () => {
    return axios.get(API_URL + 'users/current', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        // .catch(function (error) {
        //     console.log(error);
        // })
        // .finally(function () {

        // });

};

const UsersService = {
    getUsers,
    getGroups,
    createUser,
    createGroup,
    deleteGroup,
    editGroup,
    resetPassword,
    importUsers,
    exportUsers,
    deleteUser,
    updateUser,
    proofResetPassword,
    getCurrentUser
}

export default UsersService;