import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const addToCart = (product_id, quantity, product_option_values) => {
    if (!quantity) {
        quantity = 1;
    }
    if (!product_option_values) {
        product_option_values = [];
    }
    return axios.post(API_URL + 'cart', { product_id: product_id, quantity: quantity, product_option_values: product_option_values }, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const cloneCartItem = (cartItemId) => {
    return axios.post(API_URL + 'cart/clone', { cart_item_id: cartItemId }, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const getCart = () => {
    return axios.get(API_URL + 'cart', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const deleteFromCart = (cart_item_id) => {
    let headers = AuthService.getTokenHeaders();
    headers.data = { cart_item_id: cart_item_id };
    return axios.delete(API_URL + 'cart', headers) // + product_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const updateCartItem = (cart_item_id, quantity, product_option_values) => {
    return axios.put(API_URL + 'cart', { cart_item_id: cart_item_id, quantity: quantity, product_option_values: product_option_values },
        AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const repeatOrder = (order_id) => {
    return axios.post(API_URL + 'cart/' + order_id, {}, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const setDeleted = (cart_item_id, deleted) => {
    return axios.post(API_URL + 'cart/set_deleted/' + cart_item_id, { deleted: deleted }, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const setCartInfo = (cartInfo) => {
    return axios.post(API_URL + "cart/info", cartInfo, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const CartService = {
    addToCart,
    getCart,
    deleteFromCart,
    updateCartItem,
    cloneCartItem,
    repeatOrder,
    setDeleted,
    setCartInfo
}

export default CartService;