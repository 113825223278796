
import React, { useState, useEffect } from "react";
import OrdersService from "../services/OrdersService";
import { format, parseISO } from 'date-fns'

const Orders = ({ setLoading }) => {

    const [orders, setOrders] = useState();
    const [statuses, setStatuses] = useState();

    const [isDragging, setIsDragging] = useState(false)
    const handleDragging = (dragging) => setIsDragging(dragging)
    const handleDragEnd = () => handleDragging(false)
    const handleDragStart = (e, orderId) => {
        e.dataTransfer.setData('text', orderId);
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.dropEffect = "move";
        handleDragging(true)
    }

    const handleDragOver = (e) => {
        //console.log(e);
        e.preventDefault()
    }

    const handleDrop = (e, status) => {
        e.preventDefault()
        const orderId = e.dataTransfer.getData('text');
        handleDragging(false);
        let order = orders.filter(order => order.id == orderId)[0];
        order.status = status;
        OrdersService.updateOrderStatus(orderId, status).then((response) => {
            getOrders();
        });
    }

    const getStatuses = () => {
        setLoading(true);
        OrdersService.getStatuses().then((response) => {
            setStatuses(response);
            setLoading(false);

        })
    }
    const getOrders = () => {
        setLoading(true);
        OrdersService.getOrdersAll().then(
            (response) => {
                setOrders(response);
                setLoading(false);

            }
        );
    }

    useEffect(() => {
        getOrders();
        getStatuses();
    }, []);

    const orderItems = !orders ? "Заказов пока нет" : orders.map((order) => {
        return <div className="order-list-item col-lg-3" key={order.id}>
            <div className="card">
                <h5 className="card-header"> #{order.id} | {format(parseISO(order.creation_date), "dd.MM.yyyy HH:mm")}</h5>
                <div className="card-body">
                    <p className="card-title">Сумма заказа: {order.total} руб.</p>
                    <p className="card-title">Статус: {order.status}</p>
                    <p className="card-text">
                        {order.client_name}<br />
                        {order.email}<br />
                        {order.phone}<br />

                    </p>
                    <a href={"/admin/orders/" + order.id} className="btn btn-primary">Подробнее</a>
                </div>
            </div>
        </div>
    });

    const orderKanban = !statuses || !orders ? "" : statuses.map((status) => {

        let ordersInStatus = [];
        orders.forEach((order) => {
            if (order.status == status) {
                ordersInStatus.push(order);
            }
        });

        let ordersInStatusRender = ordersInStatus.map((order) => {
            return <div className="order-list-item mb-4"
                key={order.id}
                isDragging={isDragging}
                onDragEnd={handleDragEnd}
                handleDragging={handleDragging}
                onDragStart={(e) => handleDragStart(e, order.id)}
                draggable>
                <div className="card">
                    <p className="card-header text-bold"> #{order.id} | {format(parseISO(order.creation_date), "dd.MM.yyyy HH:mm")}</p>
                    <div className="card-body">
                        <p className="card-title">Сумма заказа: {order.total} руб.</p>
                        <p className="card-title">Статус: {order.status}</p>
                        <p className="card-text">
                            {order.client_name}<br />
                            {order.email}<br />
                            {order.phone}<br />

                        </p>
                        <a href={"/admin/orders/" + order.id} className="btn btn-primary">Подробнее</a>
                    </div>
                </div>
            </div>
        });

        return <div className="col" key={status}>
            <div className={isDragging ? "orderColumn dragging" : "orderColumn"}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, status)}
            >
                <p className="h4">{status}</p>
                {ordersInStatusRender}
            </div>
        </div>
    });

    return (
        <div className="">
            <h1>Заказы</h1>
            <ul className="nav nav-pills justify-content-end" id="pills-tab" role="tablist">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Канбан</a>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item active" href="#kanban" data-bs-toggle="pill" data-bs-target="#kanban" type="button" role="tab" aria-controls="kanban" aria-selected="true">Канбан</a></li>
                        <li><a className="dropdown-item" href="#list" data-bs-toggle="pill" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">Список</a></li>
                    </ul>
                </li>
            </ul>
            <div className="tab-content order-pills-content mt-4" id="pills-tabContent">
                <div className="tab-pane fade show active orders-kanban" id="kanban" role="tabpanel" aria-labelledby="kanban-tab" tabIndex="0">
                    <div className="row mt-4">{orderKanban}</div>
                </div>
                <div className="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab" tabIndex="1">
                    <div className="row">{orderItems}</div>
                </div>

            </div>

        </div >
    );
}

export default Orders;