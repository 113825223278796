import React, { useState, useEffect, useRef } from "react";
import { format, parseISO } from 'date-fns'

const OrderStatusHistory = ({ order }) => {

    const orderHistoryItems = !order || order.status_history.length == 0 ? "Заказ создан." : order.status_history.map((statusHistoryItem) => {
        return <div key={statusHistoryItem.change_id}>{format(parseISO(statusHistoryItem.creation_date), "dd.MM.yyyy HH:mm")}: {statusHistoryItem.old_status} &rarr; {statusHistoryItem.new_status}</div>
    });

    useEffect(() => {

    }, []);



    return <div>
        <h3>История изменения статусов заказа</h3>
        <hr />
        {orderHistoryItems}
    </div>
}

export default OrderStatusHistory;