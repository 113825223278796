import React, { useState, useEffect } from 'react';

const ToTop = () => {

    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);
    return (showButton && <div className="totop">
        <a href="#" className="gototop">
            <em className="fa fa-angle-up"></em>
        </a>
    </div>)
}

export default ToTop;