import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "../services/UsersService";

const CreateUserEditor = ({ groups, onUserCreated }) => {

    const [message, setMessage] = useState("");

    useEffect(() => {


    }, []);

    const groupOptions = !groups ? "" : groups.map((group) =>
        <option key={"gr_" + group.id} value={group.id}>{group.name}</option>
    );
//TODO: User's fields
    const validationSchema = yup.object().shape({
        // fullname: yup.string().required('Введите имя'),
        // company: yup.string()
        //   .required('Введите название компании'),
        email: yup.string()
            .required('Введите email')
            .email('Email is invalid'),
        password: yup.string()
            .required('Введите пароль')
            .min(6, 'Пароль должен быть больше 6 символов')
            .max(40, 'Пароль не должен превышать 40 символов'),
        confirmPassword: yup.string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleRegister = (data) => {
        UsersService.createUser(data.email, data.password, data.userGroup ? [{id: data.userGroup}]: []).then(
            (response) => {
                onUserCreated();
                reset();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);
            }
        );

    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(handleRegister)} autoComplete="off">
                <div className="form-group">
                    <label>Email</label>
                    <input role="presentation"
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        autoComplete="new-email" />
                    <div className="form-error">{errors.email?.message}</div>
                </div>

                <div className="form-group">
                    <label>Пароль</label>
                    <input
                        name="password"
                        type="password"
                        {...register('password')}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        autoComplete="new-password" />
                    <div className="form-error">{errors.password?.message}</div>
                </div>

                <div className="form-group">
                    <label>Повторите пароль</label>
                    <input 
                        name="confirmPassword"
                        type="password"
                        {...register('confirmPassword')}
                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        autoComplete="new-password" />
                    <div className="form-error">{errors.confirmPassword?.message}</div>
                </div>

                <div className="form-group">
                    <label>Группа</label>
                    <select name="userGroup"
                        {...register('userGroup')}
                        className="form-control"
                        >
                        <option value="">Без группы</option>
                        {groupOptions}
                    </select>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Добавить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default CreateUserEditor;