import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CategoriesService from "./services/CategoriesService";

const Categories = ({ urlPrefix }) => {

    const [categories, setCategories] = useState("");
    const [subcategories, setSubcategories] = useState("");
    const { categoryId } = useParams();
    const [allCategories, setAllCategories] = useState([]);


    useEffect(() => {
        CategoriesService.getCategories('null').then(
            (response) => {
                setCategories(response);
            }
        );
        CategoriesService.getAllCategories().then(
            (response) => {
                //console.log(allCategories);
                setAllCategories(response);
            }
        );
        if (categoryId) {
            CategoriesService.getCategories(categoryId).then(
                (response) => {
                    //console.log(response);
                    if (response) {
                        setSubcategories(response);
                    }
                }
            );
        } else {
            setSubcategories("");
        }

    }, []);

    let categoryTree = [];

    const buildCategoryTreeParents = (categoryId) => {
        let currentCat = allCategories.filter(cat => cat.id == categoryId);
        
        if (currentCat.length > 0) {
            currentCat[0].is_current = true;
            let siblings = allCategories.filter(cat => cat.parent_id == currentCat[0].parent_id);
            buildCategoryTreeParents(currentCat[0].parent_id);
            categoryTree.push(siblings);
        }
    }

    buildCategoryTreeParents(categoryId);
    let children = allCategories.filter(cat => cat.parent_id == categoryId);
    categoryTree.push(children);
    //console.log(categoryTree);

    const categoryItems = !categories ? "" : categories.map((category) => {
        return <a href={"/" + urlPrefix + "/" + category.id} type="button" className="btn btn-outline-secondary" key={category.id}>{category.name}</a>
    }
    );

    const subcategoryItems = !subcategories ? "" : subcategories.map((category) => {
        return <a href={"/" + urlPrefix + "/" + category.id} type="button" className="btn btn-outline-secondary" key={category.id}>{category.name}</a>
    }
    );


    const categoryTreeRender = categoryTree.length == 0 ? "" : categoryTree.map((subcategories) => {
        let parentId = "null";
        const items = !subcategories ? "" : subcategories.map((category) => {
            parentId = category.parentId;
            return <a key={category.id} href={"/" + urlPrefix + "/" + category.id} type="button" className={category.is_current ? "btn btn-secondary mb-2" : "btn btn-outline-secondary mb-2"}>{category.name}</a>
        });

        return <div key={"cat_" + parentId} className="">{items}</div>
    });

    return (<div className="cat_items">
        <div className=""><a href={"/" + urlPrefix} className="btn btn-outline-success mb-2">Весь каталог</a></div>
        {categoryTreeRender}
    </div>)

}

export default Categories;