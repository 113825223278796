import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CartService from "./services/CartService";


const PriceListCategory = ({ category, level, refreshCart, setLoading, isProductInCart }) => {
    const [error, setError] = useState("");

    const addToCart = (e, product_id) => {
        if (isProductInCart(product_id)) return false;

        CartService.addToCart(product_id).then(
            (response) => {
                e.target.htmlContent = "✓";
                e.target.classList.add("btn-success");
                e.target.classList.remove("btn-secondary");
                refreshCart();
                
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    }

    useEffect(() => {

    }, []);

    const products = category.products.map((product) => {
        const margins = product.margins.map((m) => m.margin_value).sort((a, b) => a > b ? 1 : -1);
        const marginsRender = margins.length == 0 ? "" : margins.map((margin) => {
            return <div key={product.id + "_" + margin} className="col-2">{product.price + margin} руб.</div>
        });

        return <div key={product.id} className="price-list-product">
            {margins.length == 2 &&
                <div className="row align-items-center">
                    <div className="col-5">{product.name}</div>
                    {marginsRender}
                    <div className="col-2">{product.price} руб.</div>
                    <div className="col-1">
                        <button onClick={(e) => addToCart(e, product.id)} className={isProductInCart(product.id) ? "btn btn-success btn-to-cart" : "btn btn-secondary btn-to-cart"}>
                            {isProductInCart(product.id) ? "✓" : <em className="fa fa-shopping-cart"></em>}
                        </button>
                    </div>
                </div>
            }
            {margins.length == 0 && (
                <div className="row align-items-center">
                    <div className="col-5">{product.name}</div>
                    <div className="col-6">{product.price} руб.</div>
                    <div className="col-1">
                        <button onClick={(e) => addToCart(e, product.id)} className="btn btn-secondary btn-to-cart">
                            {isProductInCart(product.id) ? "✓" : <em className="fa fa-shopping-cart"></em>}
                        </button>
                    </div>
                </div>
            )}
        </div>
    });

    const subcategories = category.subcategories.map((subcat) => {
        return <PriceListCategory key={subcat.category_id} category={subcat} level={level + 1} refreshCart={refreshCart} setLoading={setLoading} isProductInCart={isProductInCart} />
    });

    const tableHeader = <div className="">
        {category.category_id == 1 &&
            <div className="row align-items-center">
                <div className="col-5"></div>
                <div className="col-2">Цена от 300 кг</div>
                <div className="col-2">Цена от 40 кг</div>
                <div className="col-2">Цена от 10 кг</div>
                <div className="col-1"></div>
            </div>
        }
        {category.category_id == 4 &&
            <div className="row align-items-center mb-4">
                <div className="col-5"></div>
                <div className="col-6">Цена</div>
                <div className="col-1"></div>
            </div>
        }
        {category.category_id == 30 &&
            <div className="row align-items-center mb-4">
                <div className="col-5"></div>
                <div className="col-6">Цена за 1 шт.</div>
                <div className="col-1"></div>
            </div>
        }
        {category.category_id == 31 &&
            <div className="row align-items-center mb-4">
                <div className="col-5"></div>
                <div className="col-2">Цена от 30 шт.</div>
                <div className="col-2">Цена от 20 шт.</div>
                <div className="col-2">Цена от 10 шт.</div>
                <div className="col-1"></div>
            </div>
        }
    </div >;

    return (
        <div className="price-list-category">
            <p className={'h' + level}>{category.name}</p>
            {tableHeader}
            {products}
            {subcategories}
        </div>
    )

}

export default PriceListCategory;