import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "../services/UsersService";

const GroupEditor = ({ group, onGroupCreated }) => {

    const [message, setMessage] = useState("");

    useEffect(() => {


    }, []);

    const validationSchema = yup.object().shape({
        // fullname: yup.string().required('Введите имя'),
        // company: yup.string()
        //   .required('Введите название компании'),
        name: yup.string()
            .required('Введите название группы')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleCreate = (data) => {
        UsersService.createGroup(data.name).then(
            (response) => {
                onGroupCreated();
                reset();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);
            }
        );

    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(handleCreate)} autoComplete="off">
                <div className="form-group">
                    <label>Название группы</label>
                    <input role="presentation"
                        name="name"
                        type="text"
                        {...register('name')}
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.name?.message}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Добавить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default GroupEditor;