import { Fragment } from "react";

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import UsersService from "./services/UsersService";
import AuthService from "./services/AuthService";


function DisabledUser() {

    return (
        <Fragment>
            <h1>Учетная запись отключена</h1>
            <section>

                <div className='container'>
                    <h2>Ваша учетная запись отключена</h2>
                    <p>Пожалуйста, свяжитесь с администрацией.</p>
                </div>
            </section>
        </Fragment>
    );
}

export default DisabledUser;