import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns'
import OrdersService from "../services/OrdersService";
import OrderItems from "../common/OrderItems";
import OrderDocs from "../common/OrderDocs";
import OrderStatusHistory from "../common/OrderStatusHistory";


const Order = ({ setLoading }) => {
    const { orderId } = useParams();
    const [error, setError] = useState("");
    const [order, setOrder] = useState("");
    const [orderDocs, setOrderDocs] = useState("");

    const [managerComment, setManagerComment] = useState("");

    const getOrder = () => {
        setLoading(true);
        OrdersService.getOrder(orderId).then(
            (response) => {
                setOrder(response);
                setManagerComment(response.manager_comment);
                console.log(response);
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        getOrder();

    }, []);

    const handleChangeManagerComment = (e) => {
        setManagerComment(e.target.value);
    };
    const updateManagerComment = () => {
        order.manager_comment = managerComment;
        OrdersService.updateOrder(order).then(
            (response) => {
                console.log(response);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    };

    return (
        <div className="">
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}
            <h1>Заказ #{orderId}</h1>
            <hr />
            {order != "" && (<div>
                <p>Дата оформления заказа: {format(parseISO(order.creation_date), "dd.MM.yyyy HH:mm")}</p>
                <p>Статус: {order.status}</p>
                <p>Комментарий менеджера: {managerComment}</p>
                <textarea onChange={handleChangeManagerComment} name="manager_comment" defaultValue={managerComment} className="form-control"></textarea>
                <button onClick={updateManagerComment} className="btn btn-outline-primary mt-2">Обновить комментарий</button>
                <hr />
                <OrderStatusHistory order={order} />
                        <hr />
                <div className="row">
                    <div className="col-lg-6">
                        {<OrderItems order={order} setLoading={setLoading} />}


                    </div>
                    <div className="col-lg-6">
                        <h2>Покупатель</h2>
                        <hr />
                        <p>Имя: {order.client_name}</p>
                        <p>Телефон: {order.phone}</p>
                        <p>Email: {order.email}</p>
                        <p>Комментарий покупателя: {order.client_comment}</p>
                        <hr />
                        <h2>Доставка</h2>
                        <hr />
                        <p>Адрес доставки: {order.address}</p>
                        <p>Способ доставки: {order.delivery_type}</p>
                        <p>Дополнительная информация о доставке: {order.delivery_comment}</p>
                        <hr />
                        <h2>Получатель доставки</h2>
                        <hr />
                        <p>Имя получателя заказа: {order.name_order_accepter}</p>
                        <p>Телефон получателя заказа: {order.phone_order_accepter}</p>
                        <hr />
                        
                        
                        <OrderDocs setLoading={setLoading} setError={setError} allowUpload={true}/>
                        
                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default Order;