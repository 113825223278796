import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import AuthService from "../services/AuthService";

import Strings from "../common/Strings";

const EMAIL_REGX = `/^\S+@\S+\.\S+$/`;

const Register = () => {
    const navigate = useNavigate();
    if(AuthService.getCurrentUser()) {
        navigate("/");
    } 

    const [message, setMessage] = useState();
    const validationSchema = yup.object().shape({
        email: yup.string()
            .required('Введите email')
            .email(Strings['validation']['email']),
            //.matches(EMAIL_REGX, Strings['validation']['email']),
        password: yup.string()
            .required('Введите пароль')
            .min(6, 'Пароль должен быть больше 6 символов')
            .max(40, 'Пароль не должен превышать 40 символов'),
        confirmPassword: yup.string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleRegister = (data) => {

        AuthService.register(data.email, data.password).then(
            (response) => {
                reset();
                navigate("/login");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage);
            }
        );


    };

    return (
        <div className="d-flex justify-content-center align-items-center flex-column">
            <h1>Регистрация</h1>
            <form onSubmit={handleSubmit(handleRegister)}>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.email?.message}</div>
                </div>

                <div className="form-group">
                    <label>Пароль</label>
                    <input
                        name="password"
                        type="password"
                        {...register('password')}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.password?.message}</div>
                </div>

                <div className="form-group">
                    <label>Повторите пароль</label>
                    <input
                        name="confirmPassword"
                        type="password"
                        {...register('confirmPassword')}
                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.confirmPassword?.message}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Регистрация
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default Register;