import React, { useState, useEffect } from "react";
import CheckoutItem from "./CheckoutItem";
import CheckoutForm from "./CheckoutForm";
import CategoriesService from "./services/CategoriesService";

const Checkout = ({ cart, refreshCart, setLoading }) => {
//console.log(cart);
    const [catTree, setCatTree] = useState();

    const getCategories = () => {
        setLoading(true);
        CategoriesService.getAllCategories().then(

            (response) => {
                //console.log(response);
                let catTree = [];
                response.sort((a, b) => a.id > b.id ? 1 : -1)
                    .forEach((cat) => {
                        if (cat.parent_id == null) {
                            catTree.push({ id: cat.id, name: cat.name, children: [] });
                        } else {
                            catTree.forEach((parentCat) => {
                                if (cat.parent_id == parentCat.id || parentCat.children.indexOf(cat.parent_id) != -1) {
                                    parentCat.children.push(cat.id);
                                }
                            });
                        }
                    });
                //console.log(catTree);
                setCatTree(catTree);
                setLoading(false);
            },
            (error) => {
                // const _content =
                //     (error.response && error.response.data) ||
                //     error.message ||
                //     error.toString();
                // setError(_content);
                setLoading(false);
            }
        );
    };

    useEffect(() => {

        refreshCart();
        getCategories();

    }, []);

    //console.log(cart);

    const cartItems = !cart.items ? "Корзина пуста" : cart.items.map((cartItem) => {
        return <CheckoutItem key={cartItem.cart_item_id} cartItem={cartItem} refreshCart={refreshCart} />;
    });

    //group all same products and count sums 
    const groupedCartItems = !cart.items ? [] : cart.items.reduce((groupedItems, currentItem) => {
        //console.log(currentItem);
        let it = groupedItems.find(x => x.id === currentItem.product.id);
        if (!it) {
            it = {
                "id": currentItem.product.id,
                "name": currentItem.product.name,
                "items": [currentItem],
                "sums": [],
                "enabled": currentItem.product.enabled,
                "subtotal": currentItem.deleted == 1 ? 0 : parseFloat(currentItem.subtotal),
                "category_id": currentItem.product.categories[0].id
            };
            groupedItems.push(it);
        } else {
            it["items"].push(currentItem);
            it["subtotal"] += currentItem.deleted == 1 ? 0 : parseFloat(currentItem.subtotal);
        }


        currentItem.selected_option_values.forEach((item, index) => {
            let optionSum = it["sums"].find(x => x.option_id == item.option_id);
            if (!optionSum) {
                optionSum = {
                    "option_id": item.option_id,
                    "name": item.option_name,
                    "sum": currentItem.deleted == 1 ? 0 : (item.option_name == "Вес" ? parseFloat(item.option_value_value) : parseFloat(item.price_alteration_value)) * parseFloat(currentItem.quantity),
                };
                it["sums"].push(optionSum);
            } else {
                optionSum["sum"] += currentItem.deleted == 1 ? 0 : (item.option_name == "Вес" ? parseFloat(item.option_value_value) : parseFloat(item.price_alteration_value)) * parseFloat(currentItem.quantity)
            }

        });


        return groupedItems;
    }, []);

    const cartRestrictions = cart.order_option_restrictions && cart.order_option_restrictions.length != 0 ? cart.order_option_restrictions.map((restriction) => {
        
        const dim = restriction.message.indexOf(" шт.") == -1 ? "г." : "шт."
        return <p className="error" key={restriction.id}>{restriction.message} Необходимо добавить еще {restriction.sum_option_value_min - restriction.current_option_value_sum} {dim}</p>
    }) : "";


    const groupedCartItemsInRootCategories = !catTree || !groupedCartItems ? "Корзина пуста" : catTree.map((cat) => {
        let catItems = [];

        groupedCartItems.forEach((elem) => {
            if (elem.category_id == cat.id || cat.children.indexOf(elem.category_id) != -1) {
                catItems.push(elem);
            }
        });

        const groupedCartItemsRender = !catItems || catItems.length == 0 ? "" : catItems.map((groupedItem) => {
            let items = groupedItem["items"].map((cartItem) => {
                return <CheckoutItem key={cartItem.cart_item_id} cartItem={cartItem} refreshCart={refreshCart} />;
            });

            return (<div key={groupedItem.name} className="grouped-checkout-item">
                <p className={"h4"  + (!groupedItem.enabled ? " text-danger" : "")}>{groupedItem.name} {!groupedItem.enabled && "!!!временно недоступен!!!"}</p>
                {items}
                {groupedItem["sums"].sort((a, b) => a.option_id > b.option_id ? 1 : -1).length > 0 && (
                    <div className="checkout-item subtotal-checkout">
                        <div className="d-flex row flex-wrap">{groupedItem["sums"].map(x =>
                            <div className={"col option_" + x.option_id} key={x.option_id}>
                                {x["sum"] > 0 && (
                                    <div>{x["sum"]} {x.name == "Вес" ? "грамм" : "руб."}</div>
                                )}
                            </div>
                        )}
                            <div className="col text-center">Всего: {groupedItem["subtotal"]} руб.</div>

                        </div>
                    </div>
                )}
            </div>);
        });






        return (groupedCartItemsRender && (<div className="checkout-category" key={cat.id}>
            <p className="h2 mb-4">{cat.name}</p>
            {groupedCartItemsRender}
        </div>)

        );



    });

    // const groupedCartItemsRender = !groupedCartItems || groupedCartItems.length == 0 ? "Корзина пуста" : groupedCartItems.map((groupedItem) => {
    //     let items = groupedItem["items"].map((cartItem) => {
    //         return <CheckoutItem key={cartItem.cart_item_id} cartItem={cartItem} refreshCart={refreshCart} />;
    //     });

    //     return (<div key={groupedItem.name} className="grouped-checkout-item">
    //         <p className="h4">{groupedItem.name}</p>
    //         {items}
    //         {groupedItem["sums"].sort((a, b) => a.option_id > b.option_id ? 1 : -1).length > 0 && (
    //             <div className="checkout-item subtotal-checkout">
    //                 <div className="d-flex row flex-wrap">{groupedItem["sums"].map(x =>
    //                     <div className={"col option_" + x.option_id} key={x.option_id}>
    //                         {x["sum"] > 0 && (
    //                             <div>{x["sum"]} {x.name == "Вес" ? "грамм" : "руб."}</div>
    //                         )}
    //                     </div>
    //                 )}
    //                     <div className="col text-center">Всего: {groupedItem["subtotal"]} руб.</div>

    //                 </div>
    //             </div>
    //         )}
    //     </div>);
    // });

    return (
        <div className="checkout-container">
            <h1>Оформление заказа</h1>

            {groupedCartItemsInRootCategories}
            <hr />
            {groupedCartItems.length > 0 && (
                <div>
                    <p className="h3">Всего: {cart.total} руб.</p>
                    <hr />

                    {!cartRestrictions && (
                        <CheckoutForm refreshCart={refreshCart} cart={cart} setLoading={setLoading} />
                    )}

                    {cartRestrictions && (
                        <div className="alert alert-danger">
                            {cartRestrictions}
                        </div>
                    )}
                </div>
            )}
        </div>
    );

};

export default Checkout;