import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getProducts = (category_id) => {

    let params = "page_size=10000000000&include_subcategories=true";
    if (category_id) {
        if (params) {
            params = params + "&";
        }
        params = params + "category_id=" + category_id;
    }

    return axios.get(API_URL + 'products?' + params, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const getProductsTree = (category_id) => {

    let url = category_id ? 'products/tree?category_id=' + category_id : 'products/tree';

    return axios.get(API_URL + url, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const importProducts = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    //console.log(formData);
    return axios.post(API_URL + 'products/upload', formData, headers).then(response => {
        return response.data;
    }).catch(err => console.log(err))
}

const exportProducts = () => {
    return axios({
        url: API_URL + 'products/download',
        method: 'GET',
        responseType: 'blob',
        headers: AuthService.getTokenHeaders().headers
    }, AuthService.getTokenHeaders()).then((response) => {
        return response;
    });
}

const importProductsNew = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    //console.log(formData);
    return axios.post(API_URL + 'products/upload_new_table', formData, headers).then(response => {
        return response.data;
    });
}

const exportProductsNew = () => {
    return axios({
        url: API_URL + 'products/download_new_table',
        method: 'GET',
        responseType: 'blob',
        headers: AuthService.getTokenHeaders().headers
    }, AuthService.getTokenHeaders()).then((response) => {
        return response;
    });
}

const importProductsImages = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    //console.log(formData);
    return axios.put(API_URL + 'images/archive', formData, headers).then(response => {
        return response.data;
    });
}

const ProductsService = {
    getProducts,
    importProducts,
    exportProducts,
    getProductsTree,
    importProductsImages,
    importProductsNew,
    exportProductsNew
}

export default ProductsService;