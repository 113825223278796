import React, { useState, useEffect } from "react";
import CartService from "./services/CartService";

//TODO: save in settings and refactor
const allowedPackageByWeight = [
    { id: 15, weight: 1000, restrictedIds: [], allowed: [] },
    { id: 14, weight: 500, restrictedIds: [9, 11, 12], allowed: ["Серебро без клапана", "Серебро с клапаном", "Чёрный глянец с клапаном"] },
    { id: 13, weight: 250, restrictedIds: [8, 9, 10], allowed: ["Серебро без клапана", "Белый матовый квадропак с клапаном", "Чёрный матовый квадропак с клапаном"] }
];

const CheckoutItem = ({ cartItem, refreshCart }) => {

    let sw = cartItem.selected_option_values.filter(option_value => option_value.option_name == "Вес");
    let selected_weight = sw.length > 0 ? sw[0].option_value_value : 0;
    let allowedPackages = allowedPackageByWeight.filter(pack => pack.weight == selected_weight)[0];


    let productOptionValues = [];
    if (cartItem.product.option_values) {
        let optionIds = Array.from(new Set(cartItem.product.option_values.map((item) => item.option_id)));

        productOptionValues = optionIds.map((optionId) => {
            let selected = cartItem.selected_option_values.filter(option_value => option_value.option_id == optionId);
            let selectedValueId = "";
            if (selected.length == 1) {
                selectedValueId = selected[0].option_value_id;
            }

            let options = cartItem.product.option_values.filter(option_value => option_value.option_id == optionId).map((option_value) => {
                return { option_value_id: option_value.option_value_id, option_value_name: option_value.option_value_name }
            });

            return { optionId: optionId, optionValues: options, selectedValueId: selectedValueId ? selectedValueId : options[0].option_value_id }
        });
    }



    const [selectedOptionValues, setSelectedOptionValues] = useState("");

    const deleteFromCart = (e, cart_item_id) => {
        CartService.deleteFromCart(cart_item_id).then(
            (response) => {
                refreshCart();
            }
        );
    }

    const setDeleted = (e, cart_item_id, deleted) => {
        CartService.setDeleted(cart_item_id, deleted).then(
            (response) => {
                refreshCart();
            }
        );
    }

    const copyCartItem = (e, cartItemId) => {
        CartService.cloneCartItem(cartItemId).then(
            (response) => {
                refreshCart();
            }
        );
    }

    const updateOptionValues = (e, optionId) => {

        let optionValues = productOptionValues.map((item) => {
            return item.optionId == optionId ? parseInt(e.target.value) : item.selectedValueId
        });

        //normalize packs and weight
        const defaultPackId = 7;

        let restrictedWeight = allowedPackageByWeight.filter((x) => x.restrictedIds.length > 0).map((y) => y.id);
        var containsWeight = restrictedWeight.filter((n) => optionValues.indexOf(n) !== -1);

        if (containsWeight.length > 0) {
            let rest = allowedPackageByWeight.filter((x) => x.id == containsWeight[0])[0].restrictedIds;
            var containsRestricted = rest.filter((n) => optionValues.indexOf(n) !== -1);

            if (containsRestricted.length == 1) {
                optionValues = optionValues.filter((n) => n != containsRestricted[0]);
                optionValues.push(defaultPackId);
            }
        }
        //end normalize packs and weight

        CartService.updateCartItem(cartItem.cart_item_id, cartItem.quantity, optionValues).then(
            (response) => {
                refreshCart();
            }
        );
    }

    const updateQuantity = (e) => {

        let qu = e.target.value;

        let optionValues = productOptionValues.map((item) => item.selectedValueId);
        CartService.updateCartItem(cartItem.cart_item_id, qu, optionValues).then(
            (response) => {
                refreshCart();
            }
        );
    }

    let product_option_values = "";

    if (cartItem.product.option_values) {
        let optionIds = Array.from(new Set(cartItem.product.option_values.map((item) => item.option_id)));

        product_option_values = optionIds.map((optionId) => {
            let selected = cartItem.selected_option_values.filter(option_value => option_value.option_id == optionId);
            let selectedValueId = "";
            if (selected.length == 1) {
                selectedValueId = selected[0].option_value_id;
            }
            let options = cartItem.product.option_values.filter(option_value => option_value.option_id == optionId &&
                (option_value.option_name != "Упаковка" || selected_weight == 1000 || allowedPackages.allowed.indexOf(option_value.option_value_name) != -1))
                .map((option_value) => {
                    return <option key={cartItem.cartItemId + "_" + option_value.option_value_id} value={option_value.option_value_id}>
                        {option_value.option_value_name} {option_value.price_alteration_value > 0 && (" - " + option_value.price_alteration_value + " руб./шт.")}
                    </option>
                });

            return <div className={"col option_" + optionId} key={cartItem.cartItemId + "_" + optionId}><select value={selectedValueId} className="form-control" onChange={(e) => updateOptionValues(e, optionId)}>{options}</select></div>
        });
    }

    return <div className={"checkout-item" + (cartItem.deleted == 1 ? " deleted-item" : "")}>
        <div className="row d-flex align-items-center justify-content-between flex-wrap" key={cartItem.cartItemId}>

            {product_option_values}

            <div className="col option_other d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <input type="number" defaultValue={cartItem.quantity} className="form-control form-control-number me-1" onBlur={(e) => updateQuantity(e)} />
                    шт.
                </div>
                <div className="subtotal">{cartItem.subtotal} руб.</div>
                {cartItem.deleted == 0 &&
                    <div className="">
                        <button onClick={(e) => setDeleted(e, cartItem.cart_item_id, 1)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                        <button onClick={(e) => copyCartItem(e, cartItem.cart_item_id)} className="btn btn-secondary"><i className="fa fa-copy"></i></button>
                    </div>
                }
                {cartItem.deleted == 1 &&
                    <div className="">
                        <button onClick={(e) => deleteFromCart(e, cartItem.cart_item_id)} className="btn btn-outline-secondary"><i className="fa fa-close"></i></button>
                    </div>
                }
            </div>
            {cartItem.deleted == 1 &&
                <p>Позиция удалена из корзины. Нажмите, чтобы вернуть ее в заказ: <a href="#" onClick={(e) => setDeleted(e, cartItem.cart_item_id, 0)}>Восстановить</a></p>
            }
        </div>
    </div>
};

export default CheckoutItem;