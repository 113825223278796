import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getSettings = () => {

    return axios.get(API_URL + 'settings', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};

const upsertSettings = (key, value) => {
    return axios.post(API_URL + "settings", {key: key, value: value}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};


const deleteSettings = (key) => {
    let headers = AuthService.getTokenHeaders();
    headers.data = { key: key };
    return axios.delete(API_URL + 'settings', headers)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
};


const SettingsService = {
    getSettings,
    upsertSettings,
    deleteSettings
}

export default SettingsService;