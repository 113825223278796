import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import AuthService from "./services/AuthService";
import OrdersService from "./services/OrdersService";
import { useNavigate } from "react-router-dom"
import CartService from "./services/CartService";


const CheckoutForm = ({ refreshCart, cart, setLoading }) => {
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        fullname: yup.string().required('Введите имя'),
        fullnamereceiver: yup.string().required('Введите имя'),
        deliverytype: yup.string(),
        deliverycomment: yup.string(),
        comment: yup.string(),
        email: yup.string()
            .required('Введите email')
            .email('Введите правильный email'),
        phone: yup.string()
            .required('Введите номер телефона'),
        phonereceiver: yup.string()
            .required('Введите номер телефона'),
        address: yup.string().when('deliverytype', {
            is: (deliverytype) => deliverytype != "Самовывоз",
            then: yup.string()
                .required('Введите адрес доставки')
        })

        //yup.string().required('Введите адрес'),

    });

    const currentUser = AuthService.getCurrentUser();
    //console.log(cart);
    const { register, handleSubmit, reset, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: cart && cart.email ? cart.email : currentUser.user && currentUser.user.email ? currentUser.user.email : "",
            deliverytype: cart && cart.delivery_type ? cart.delivery_type : 'Самовывоз',
            fullname: cart && cart.client_name ? cart.client_name : "",
            address: cart && cart.address ? cart.address : "",
            phone: cart && cart.phone ? cart.phone : "",
            client_comment: cart && cart.comment ? cart.comment : "",
            deliverycomment: cart && cart.delivery_comment ? cart.delivery_comment : "",
            fullnamereceiver: cart && cart.name_order_accepter ? cart.name_order_accepter : "",
            phonereceiver: cart && cart.phone_order_accepter ? cart.phone_order_accepter : ""
        }
    });

    const handleCheckoutSubmit = (data) => {
        //console.log(data);
        const orderData = {
            phone: data.phone,
            email: data.email,
            client_name: data.fullname,
            address: data.address,
            delivery_type: data.deliverytype,
            client_comment: data.comment,
            delivery_comment: data.deliverycomment,
            name_order_accepter: data.fullnamereceiver,
            phone_order_accepter: data.phonereceiver
        }
        setLoading(true);
        OrdersService.place_an_order(orderData).then((response) => {
            setLoading(false);
            refreshCart();
            navigate("/success");
        });

    };

    const updateCartInfo = () => {

        const data = getValues();
        const cartData = {
            phone: data.phone,
            email: data.email,
            client_name: data.fullname,
            address: data.address,
            delivery_type: data.deliverytype,
            client_comment: data.comment,
            delivery_comment: data.deliverycomment,
            name_order_accepter: data.fullnamereceiver,
            phone_order_accepter: data.phonereceiver
        }

        CartService.setCartInfo(cartData).then((response) => {
            
        });
    }


    return <div className="order-form">
        <h2>Ваши контакты</h2>

        <form onSubmit={handleSubmit(handleCheckoutSubmit)}>
            <div className="form-group">
                <label>Телефон</label>
                <input
                    name="phone"
                    type="text"
                    {...register('phone', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.phone?.message}</div>
            </div>
            <div className="form-group">
                <label>Email</label>
                <input
                    name="email"
                    type="text"
                    {...register('email', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.email?.message}</div>
            </div>
            <div className="form-group">
                <label>Полное имя</label>
                <input
                    name="fullname"
                    type="text"
                    {...register('fullname', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control ${errors.fullname ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.fullname?.message}</div>
            </div>
            <div className="form-group">
                <label>Способ доставки</label>
                <select name="deliverytype" className="form-control" {...register('deliverytype', {onChange: (e) => {updateCartInfo();}})}>
                    <option>Самовывоз</option>
                    <option>Курьерская служба FreshCoffee</option>
                    <option>Курьерская служба заказчика</option>
                </select>
            </div>
            <div className="form-group">
                <label>Дополнительная информация о доставке</label>
                <textarea
                    name="deliverycomment"
                    rows="2"
                    {...register('deliverycomment', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control`}
                    autoComplete="off" />
            </div>
            <div className="form-group">
                <label>Адрес</label>
                <input
                    name="address"
                    type="text"
                    {...register('address', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.address?.message}</div>
            </div>
            <div className="form-group">
                <label>Комментарий к заказу</label>
                <textarea
                    name="comment"
                    rows="2"
                    {...register('comment', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control`}
                    autoComplete="off" />
            </div>
            <div className="form-group">
                <label>Полное имя получателя заказа</label>
                <input
                    name="fullnamereceiver"
                    type="text"
                    {...register('fullnamereceiver', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control ${errors.fullnamereceiver ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.fullnamereceiver?.message}</div>
            </div>
            <div className="form-group">
                <label>Телефон получателя заказа</label>
                <input
                    name="phonereceiver"
                    type="text"
                    {...register('phonereceiver', {onBlur: (e) => {updateCartInfo();}})}
                    className={`form-control ${errors.phonereceiver ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.phonereceiver?.message}</div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="btn btn-primary">
                    Оформить заказ
                </button>
            </div>
        </form>
    </div>
}

export default CheckoutForm