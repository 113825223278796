import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "../services/UsersService";

const UserProfile = () => {
    const [message, setMessage] = useState("");
    const validationSchema = yup.object().shape({
        fullname: yup.string().required('Введите имя'),
        company: yup.string(),
        email: yup.string()
            .required('Введите email')
            .email('Email is invalid'),
        address: yup.string(),
        phone: yup.string()
            .required('Введите номер телефона')

    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleUpdate = (data) => {
        //TODO: update user

        // UsersService.updateUser(data.email, data.password, data.userGroup ? [{id: data.userGroup}]: []).then(
        //     (response) => {
        //         onUserCreated();
        //     },
        //     (error) => {
        //         const resMessage =
        //             (error.response &&
        //                 error.response.data &&
        //                 error.response.data.message) ||
        //             error.message ||
        //             error.toString();

        //         setMessage(resMessage);
        //     }
        // );

    };

    return (
        <div className="">
            <h2>Профиль</h2>
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <div className="form-group">
                    <label>Телефон</label>
                    <input
                        name="phone"
                        type="text"
                        {...register('phone')}
                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.phone?.message}</div>
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input role="presentation"
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        autoComplete="new-email" />
                    <div className="form-error">{errors.email?.message}</div>
                </div>
                <div className="form-group">
                    <label>Полное имя</label>
                    <input
                        name="fullname"
                        type="text"
                        {...register('fullname')}
                        className={`form-control ${errors.fullname ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.fullname?.message}</div>
                </div>
                <div className="form-group">
                    <label>Адрес</label>
                    <input
                        name="address"
                        type="text"
                        {...register('address')}
                        className={`form-control`}
                        autoComplete="off" />
                </div>
                <div className="form-group">
                    <label>Название компании</label>
                    <input
                        name="company"
                        type="text"
                        {...register('company')}
                        className={`form-control`}
                        autoComplete="off" />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Сохранить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
}

export default UserProfile;