import OrdersHistory from "./OrdersHistory";
import UserProfile from "./UserProfile";

const Personal = ({setLoading}) => {
    return (
        <div className="">
            <h1>Личный кабинет</h1>
            <div className="row">
                <div className="col-lg-6">
                    <OrdersHistory setLoading={setLoading}/>
                </div>
                <div className="col-lg-6">
                    <UserProfile />
                </div>
            </div>
        </div>
    );
}

export default Personal;