import React, { useState, useRef, useEffect } from "react";


const FileUploader = ({uploadFunc}) => {

    const [file, setFile] = useState(''); // storing the uploaded file    
    // storing the recived file from backend
    //const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0); // progess bar
    const [error, setError] = useState("");
    const el = useRef(); // accesing input element
    const handleChange = (e) => {
        setProgess(0)
        const file = e.target.files[0]; // accesing file
        console.log(file);
        setFile(file); // storing file
        // setTimeout(uploadFile, 1000);

    }
    const uploadFile = () => {
        if (!file) {
            el.current.click();
            return;
        }
        const formData = new FormData();
        formData.append('file', file); // appending file
        const onUploadProgress = (ProgressEvent) => {
            let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);
        }
        uploadFunc(formData, onUploadProgress).then(
            (response) => {
                setFile("");
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    }

    return (
        <div className="file-upload">
            <input type="file" ref={el} onChange={handleChange} className="d-none" />
            {/* <div className="progessBar" style={{ width: progress }}>
                    {progress}
                </div> */}
            <div className="d-flex align-items-center">
                <button onClick={uploadFile} className="btn btn-success me-4">
                    {file ? "Начать загрузку" : "Выбрать файл"}
                </button>
                {file ? file.name : ""}
            </div>
        </div>
    );
};

export default FileUploader;


