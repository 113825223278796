import React, { useState, useEffect, useRef } from "react";
import { format, parseISO } from 'date-fns'
import UsersService from "../services/UsersService";
import Register from "../auth/Register";
import { createHashRouter } from "react-router-dom";
import { set } from "react-hook-form";
import UserEditor from "./UserEditor";
import CreateUserEditor from "./CreateUserEditor";
import GroupEditor from "./GroupEditor"

const Users = () => {
    const modalUser = useRef();
    const modalGroup = useRef();

    const [users, setUsers] = useState("");
    const [groups, setGroups] = useState("");

    const [error, setError] = useState("");

    const refreshUsers = () => {
        UsersService.getUsers().then(
            (response) => {
                setUsers(response);
                console.log(response);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    };

    const refreshGroups = () => {
        UsersService.getGroups().then(
            (response) => {
                setGroups(response);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    };

    useEffect(() => {
        refreshUsers();
        refreshGroups();
    }, []);

    const onUserCreated = (data) => {
        modalUser.current.click();

        refreshUsers();
    };

    const onGroupCreated = (data) => {
        modalGroup.current.click();

        refreshGroups();
    };

    const deleteGroup = (e, groupId) => {
        if (!window.confirm("Вы уверены, что хотите удалить группу?")) return;
        UsersService.deleteGroup(groupId).then(
            (response) => {
                refreshGroups();
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    };

    const editUser = (e, userId) => {
        //TODO: user edit
    }

    const editGroup = (e, groupId) => {
        //TODO: user group
    }

    const deleteUser = (e, userId) => {
        if (!window.confirm("Вы уверены, что хотите удалить пользователя?")) return;
        UsersService.deleteUser(userId).then(
            (response) => {
                refreshUsers();
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    };

    const userItems = !users ? "" : users.map((user) => {
        let groupsInfo = user.user_groups.map((group) => group.name).join(", ");

        return <div><div className="user-item" key={user.email}>
            <div>{user.email} | {format(parseISO(user.creation_date), "dd.MM.yyyy HH:mm")} | {user.disabled ? "Отключен" : "Активен"} | {groupsInfo} </div>
            <div>
                <button role="button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseUser" + user.id} aria-expanded="false" aria-controls={"#collapseUser" + user.id} className="btn btn-secondary"><i className="fa fa-pencil"></i></button>
                <button onClick={(e) => deleteUser(e, user.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
            </div>

        </div>
            <div id={"collapseUser" + user.id} className="collapse">
                <div className="mb-4 mt-4">
                    <UserEditor groups={groups} userInfo={user} onUserUpdated={refreshUsers} />
                </div>
            </div>
        </div>

    }
    );

    const groupItems = !groups ? "" : groups.map((group) =>
        <div className="group-item" key={group.id}>
            <div>{group.name}</div>
            <div>
                <button onClick={(e) => editGroup(e, group.id)} className="btn btn-secondary"><i className="fa fa-pencil"></i></button>
                <button onClick={(e) => deleteGroup(e, group.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
            </div>
        </div>
    );

    return (
        <div className="">
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}

            <div className="row">
                <div className="col-md-6">
                    <h2>Пользователи</h2>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newUserModal">
                        Новый пользователь
                    </button>
                    <div className="users-list mt-4">{userItems}</div>
                </div>
                <div className="col-md-6">
                    <h2>Группы</h2>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newGroupModal">
                        Новая группа
                    </button>
                    <div className="mt-4">{groupItems}</div>
                </div>
            </div>

            <div className="modal fade" id="newUserModal" tabIndex="-1" aria-labelledby="newUserModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Новый пользователь</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalUser}></button>
                        </div>
                        <div className="modal-body">
                            <CreateUserEditor groups={groups} userInfo={null} onUserCreated={onUserCreated} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="newGroupModal" tabIndex="-1" aria-labelledby="newGroupModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Новый пользователь</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalGroup}></button>
                        </div>
                        <div className="modal-body">
                            <GroupEditor group={null} onGroupCreated={onGroupCreated} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;