import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "../services/UsersService";

import Strings from "../common/Strings";

const ForgotPassword = () => {
    const [message, setMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const validationSchema = yup.object().shape({
        email: yup.string()
            .required('Введите email')
            .email('Введите правильный email'),

    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleRestoreSubmit = (data) => {

        UsersService.resetPassword(data.email).then(
            (response) => {
                console.log(response);
                if (response.type = "ok") {
                    setSuccessMessage("На вашу почту отправлена ссылка для восстановления пароля.");
                    reset();
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage);
            }
        );
    };


    return <div>
        <h1>Восстановление пароля</h1>
        <p>Укажите ваш email, и мы отправим на него код для восстановления пароля.</p>
        <form onSubmit={handleSubmit(handleRestoreSubmit)}>
            <div className="form-group">
                <label>Email</label>
                <input
                    name="email"
                    type="text"
                    {...register('email')}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    autoComplete="off" />
                <div className="form-error">{errors.email?.message}</div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="btn btn-primary">
                    Отправить
                </button>
            </div>
        </form>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {successMessage && (<div className="alert alert-success mt-4">
            {successMessage}
        </div>)}
    </div>
}

export default ForgotPassword