import logo from './images/logo.png';
import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";


import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from './components/auth/ForgotPassword';

import Spinner from './components/common/Spinner';
import ToTop from './components/common/ToTop';

import NotFoundPage from './components/NotFoundPage';
import Home from "./components/Home"
import Cart from "./components/Cart"
import Checkout from "./components/Checkout"
import Catalog from "./components/Catalog";
import Personal from './components/personal/Personal';
import PriceList from './components/PriceList';
import OrderInfo from './components/personal/OrderInfo';

import AuthService from "./components/services/AuthService";
import CartService from "./components/services/CartService";

import ImportExport from './components/admin/ImportExport';
import Users from "./components/admin/Users";
import Orders from './components/admin/Orders';
import Order from './components/admin/Order';
import SuccessOrder from './components/SuccessOrder';
import Admin from './components/admin/Admin';
import Settings from './components/admin/Settings';
import DisabledUser from './components/DisabledUser';

import UsersService from './components/services/UsersService';

import Strings from './components/common/Strings';
import RestorePassword from './components/auth/RestorePassword';

const AuthRoute = ({ children, group, isQuestionnare }) => {

    let user = AuthService.getCurrentUser();
    if (!user) {
        return <Navigate to={"/login?return_url=" + window.location.pathname + window.location.search} />;
    } else if (user.disabled) {
        return <DisabledUser />
    }
    if (user && group && !user.user_groups.map(x => x.name).includes(group)) {
        return <Navigate to="/" />;
    }

    return children;

};


function App() {

    const [token, setToken] = useState("");
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [cart, setCart] = useState("");
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const [message, setMessage] = useState();

    const [isLoading, setLoading] = useState(false);

    const logOut = () => {
        AuthService.logout();
        window.location.reload();
    };
    //logOut();
    const refreshCart = () => {
        setLoading(true);
        CartService.getCart().then(
            (response) => {
                setCart(response);
                setLoading(false);
            }
        );
    };

    const isProductInCart = (product_id) => {
        if (!cart || !cart.items) return false;

        let prodIds = cart.items.map((cartItem) => cartItem.product_id);
        return prodIds.indexOf(product_id) != -1;
    };

    useEffect(() => {
        //const auth = AuthService.getCurrentAuth();

        if (currentUser) {
            //setCurrentAuth(auth);
            UsersService.getCurrentUser().then((user) => {
                //setCurrentUser(user);
                AuthService.setDisabled(user.disabled);

                setShowAdminMenu(user.user_groups.map(x => x.name).includes("Администратор"));

            }, (error) => {

                console.log("error", error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage);

                if (error.response && error.response.status == 403) {
                    AuthService.setDisabled(1);
                }
            });


        }
    }, [currentUser]);



    return (
        <div className="app">
            <header className="sticky-top">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src={logo} alt="" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {currentUser && (
                                < ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/"><i className="fa fa-home"></i></a>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/catalog"} className="nav-link">
                                            Каталог
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/pricelist"} className="nav-link">
                                            Прайс-лист
                                        </Link>
                                    </li>
                                    {showAdminMenu ?

                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Админ
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to={"/admin/orders"} className="nav-link">
                                                        Заказы
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to={"/admin/importexport"} className="nav-link">
                                                        Импорт/Экспорт
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to={"/admin/users"} className="nav-link">
                                                        Пользователи
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to={"/admin/settings"} className="nav-link">
                                                        Настройки
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        : ""}
                                    {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled">Disabled</a>
                            </li> */}

                                </ul>)}
                            {currentUser && (<ul className="navbar-nav mb-2 mb-lg-0 user-menu">
                                <li className="nav-item me-2 with-cart">
                                    <a href="/checkout" className="btn btn-warning">
                                        <i className="fa fa-shopping-cart"></i>
                                    </a>
                                    <Cart cart={cart} refreshCart={refreshCart} grouppedView={true} />
                                </li>
                                <li className="nav-item me-2">
                                    <a className="btn btn-warning" href="/personal">
                                        <i className="fa fa-user"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="btn btn-warning" onClick={logOut}>
                                        <i className="fa fa-power-off"></i>
                                    </a>
                                </li>
                            </ul>)}
                        </div>
                    </div>
                </nav >
            </header>
            <main>
                <div className="container">
                    {message && (<div className='container'><div className="alert alert-danger mt-4">
                        {message}
                    </div></div>)}
                    <Routes>
                        <Route exact path={"/"} element={<Home />} />
                        <Route exact path={"/register"} element={<Register />} />
                        <Route exact path={"/login"} element={<Login setCurrentAuth={setCurrentUser} />} />

                        <Route exact path={"/restore_pass"} element={<RestorePassword />} />
                        <Route exact path={"/restore-password"} element={<ForgotPassword />} />

                        <Route exact path={"/catalog"} element={<AuthRoute><Catalog refreshCart={refreshCart} setLoading={setLoading} isProductInCart={isProductInCart} /></AuthRoute>} />
                        <Route path={"/catalog/:categoryId"} element={<AuthRoute><Catalog refreshCart={refreshCart} setLoading={setLoading} isProductInCart={isProductInCart} /></AuthRoute>} />
                        <Route exact path={"/checkout"} element={<AuthRoute><Checkout refreshCart={refreshCart} cart={cart} setLoading={setLoading} /></AuthRoute>} />
                        <Route exact path={"/personal"} element={<AuthRoute><Personal setLoading={setLoading} /></AuthRoute>} />
                        <Route exact path={"/pricelist"} element={<AuthRoute><PriceList refreshCart={refreshCart} setLoading={setLoading} isProductInCart={isProductInCart} /></AuthRoute>} />
                        <Route path={"/pricelist/:categoryId"} element={<AuthRoute><PriceList refreshCart={refreshCart} setLoading={setLoading} isProductInCart={isProductInCart} /></AuthRoute>} />
                        <Route path={"/personal/orders/:orderId"} element={<AuthRoute><OrderInfo setLoading={setLoading} /></AuthRoute>} />
                        <Route exact path={"/success"} element={<AuthRoute><SuccessOrder /></AuthRoute>} />

                        <Route exact path={"/admin"} element={
                            <AuthRoute group="Администратор">
                                <Admin />
                            </AuthRoute>}>

                            <Route exact path={"users"} element={
                                <AuthRoute group="Администратор">
                                    <Users />
                                </AuthRoute>
                            } />
                            <Route exact path={"settings"} element={<Settings setLoading={setLoading} />} />
                            <Route exact path={"importexport"} element={<ImportExport setLoading={setLoading} />} />
                            <Route exact path={"orders"} element={<Orders setLoading={setLoading} />} />
                            <Route path={"orders/:orderId"} element={<Order setLoading={setLoading} />} />

                        </Route>


                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </div>
            </main>
            <footer>
                @2023 Hermes. Разработка и поддержка: <a target="_blank" href="https://synweb.ru">Синвеб</a>.
            </footer>
            {isLoading ? <Spinner /> : ""}
            {<ToTop />}
        </div >
    );
}

export default App;
