import React, { useState, useEffect, useRef } from "react";
import SettingsService from "../services/SettingsService";

const Settings = ({ setLoading }) => {
    const [error, setError] = useState("");
    const [settings, setSettings] = useState("");
    const getSettings = () => {
        setLoading(true);
        SettingsService.getSettings().then(
            (response) => {
                setSettings(response);
//                console.log(response);
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };
    useEffect(() => {
        getSettings();

    }, []);

    const updateSettings = (e, key) => {
        SettingsService.upsertSettings(key, e.target.value).then(
            (response) => {
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );


    };

    const settingsRender = !settings ? "" : settings.map((setting) => {
        return <form key={setting.key} className="row align-items-center mb-4" onSubmit={() => { return false; }}>
            <div className="col-lg-4">
                <label className="form-label">{setting.key}</label>
            </div>
            <div className="col-lg-6">
                <input className="form-control" type="text" onChange={(e) => updateSettings(e, setting.key)} defaultValue={setting.value} />
            </div>
        </form>
    });

    return <div>{settingsRender}</div>
}

export default Settings;