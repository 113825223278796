import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "../services/UsersService";

const UserEditor = ({ groups, userInfo, onUserUpdated }) => {

    const [message, setMessage] = useState("");

    useEffect(() => {


    }, []);

    const groupOptions = !groups ? "" : groups.map((group) =>
        <option key={"gr_" + group.id} value={group.id}>{group.name}</option>
    );
    //TODO: User's fields
    const validationSchema = yup.object().shape({
        // fullname: yup.string().required('Введите имя'),
        // company: yup.string()
        //   .required('Введите название компании'),
        email: yup.string()
            .required('Введите email')
            .email('Email is invalid')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: userInfo.email,
            userGroup: userInfo.user_groups.length > 0 ? userInfo.user_groups[0].id : null,
            disabled: userInfo.disabled == 1
        }
    });

    const handleUpdate = (data) => {
        UsersService.updateUser(userInfo.id, data.email,
            data.userGroup ? [{ id: data.userGroup }] : [],
            data.disabled ? 1 : 0).then(
                (response) => {
                    onUserUpdated();
                    //reset();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                }
            );

    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <div className="form-group">
                    <label>Email</label>
                    <input role="presentation"
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        autoComplete="new-email" />
                    <div className="form-error">{errors.email?.message}</div>
                </div>

                <div className="form-group">
                    <label>Группа</label>
                    <select name="userGroup"
                        {...register('userGroup')}
                        className="form-control"
                    >
                        <option value="">Без группы</option>
                        {groupOptions}
                    </select>
                </div>

                <div className="form-check">
                    <input className="form-check-input"
                        id={"disabledCheck" + userInfo.id}
                        name="disabled"
                        type="checkbox"
                        {...register('disabled')}
                    />
                    <label for={"disabledCheck" + userInfo.id}>Отключен</label>
                </div>

                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Обновить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default UserEditor;