import React, { useState, useEffect } from "react";

import CategoriesService from "../services/CategoriesService";

const OrderItems = ({ order, setLoading }) => {

    const [rootCategories, setRootCategories] = useState("");

    const getRootCategories = () => {
        CategoriesService.getRootCategoriesWithChildrenPlainList().then(
            (response) => {
                setRootCategories(response);
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                //setError(_content);
                setLoading(false);
            }
        );
    }


    useEffect(() => {
        getRootCategories();
    }, []);


    const groupedOrderItems = !order.items ? [] : order.items.reduce((groupedItems, currentItem) => {

        let it = groupedItems.find(x => x.id === currentItem.product.id);
        if (!it) {
            it = {
                "id": currentItem.product.id,
                "name": currentItem.product.name,
                "product_id": currentItem.product.id,
                "items": [currentItem],
                "sums": [],
                "subtotal": parseFloat(currentItem.subtotal),
                "category_id": currentItem.product.categories[0].id
            };
            groupedItems.push(it);
        } else {
            it["items"].push(currentItem);
            it["subtotal"] += parseFloat(currentItem.subtotal);
        }

        currentItem.product_option_values.forEach((item, index) => {
            let optionSum = it["sums"].find(x => x.option_id == item.option_id);
            if (!optionSum) {
                optionSum = {
                    "option_id": item.option_id,
                    "name": item.option_name,
                    "sum": (item.option_name == "Вес" ? parseFloat(item.option_value_value) : parseFloat(item.price_alteration_value)) * parseFloat(currentItem.quantity),
                };
                it["sums"].push(optionSum);
            } else {
                optionSum["sum"] += (item.option_name == "Вес" ? parseFloat(item.option_value_value) : parseFloat(item.price_alteration_value)) * parseFloat(currentItem.quantity)
            }

        });

        return groupedItems;
    }, []);




    //simple view
    const orderItems = !order ? "" : order.items.map((item) => {
        let product_option_values = item.product_option_values.length == 0 ? "" : item.product_option_values.map((optionValue) => {
            return <div className="col-6" key={optionValue.option_value_id}>
                {optionValue.option_name}: {optionValue.option_value_name}
            </div>
        });


        return <div className="order-item mb-4" key={item.order_item_id}>
            <div className="row">
                <div className="col-6 fw-bold">{item.product.name}</div>
                <div className="col-6 fw-bold">{item.quantity} шт.</div>
                {product_option_values}
                <div className="col-12"></div>
                <div className="col-6 fw-bold">Цена за единицу: {parseFloat(item.subtotal) / parseFloat(item.quantity)} руб.</div>
                <div className="col-6 fw-bold">Стоимость позиции: {item.subtotal} руб.</div>
            </div>
        </div>
    });

    const groupedOrderItemsInRootCategories = !rootCategories || !groupedOrderItems ? "-" : rootCategories.map((cat) => {

        if (cat.id == 1 || cat.id == 3 || cat.id == 4) {
            let catItems = [];
            groupedOrderItems.forEach((elem) => {
                if (elem.category_id == cat.id || cat.children.indexOf(elem.category_id) != -1) {
                    catItems.push(elem);
                }
            });

            const groupedOrderItemsRender = catItems.length == 0 ? "" : catItems.map((groupedItem) => {
                let orderItems = groupedItem.items.map((item) => {
                    let product_option_values = item.product_option_values.length == 0 ? "" : item.product_option_values.map((optionValue) => {
                        return <div className="col-6" key={optionValue.option_value_id}>
                            {optionValue.option_name}: {optionValue.option_value_name}
                        </div>
                    });

                    return <div className="order-item mb-2" key={item.order_item_id}>
                        <div className="row">
                            <div className="col-6 fw-bold">{item.quantity} шт.</div>
                            {product_option_values}
                            <div className="col-12"></div>
                            <div className="col-6 fw-bold">Цена за единицу: {parseFloat(item.subtotal) / parseFloat(item.quantity)} руб.</div>
                            <div className="col-6 fw-bold">Стоимость позиции: {item.subtotal} руб.</div>
                        </div>
                    </div>
                });
                return <div className="order-grouped-item mb-4" key={groupedItem.product_id}>
                    <p className="h4">{groupedItem.name}</p>
                    {orderItems}
                    {groupedItem["sums"].sort((a, b) => a.option_id > b.option_id ? 1 : -1).length > 0 && (
                        <div className="subtotal-order-item">
                            <div className="d-flex flex-wrap align-items-top">{groupedItem["sums"].map(x =>
                                <div key={x.option_id}>
                                    {x["sum"] > 0 && (
                                        <div className="m-2">{x["name"]}: {x["sum"]} {x.name == "Вес" ? "грамм" : "руб."}</div>
                                    )}
                                </div>
                            )}
                                <div className="m-2">Всего: {groupedItem["subtotal"]} руб.</div>

                            </div>
                        </div>
                    )}
                </div >

            });

            return (catItems.length > 0 && <div className="groupped-in-cat-order-items">
                <p className="h2">{cat.name}</p>
                <hr />
                {groupedOrderItemsRender}
            </div>)

        }
    });




    return <div className="">
        <h2>Товары в заказе</h2>
        <hr />
        {groupedOrderItemsInRootCategories}
        <hr />
        <p className="fs-3">Итого: {order.total} руб.</p>
    </div>
}

export default OrderItems;